import React from "react";
import PropTypes from "prop-types";

import { graphql, useStaticQuery } from "gatsby";

import i18n from "./Integration.i18n";

import Header from "./header/Header";
import ClientBrandsBlue from "../../client-brands-blue/ClientBrandsBlue";
import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";
import ThreeColumns from "../../three-columns/ThreeColumns";
import SmallButton from "../../button/small-button/SmallButton";
import Prices from "../../prices/Prices";

function Integration({ name, children, strings, locale }) {
  const data = useStaticQuery(graphql`
    query {
      illustrationLocation: file(
        relativePath: { eq: "illustration/Store_locator_preview.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationCost: file(relativePath: { eq: "illustration/cost.png" }) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationCustomCSS: file(
        relativePath: { eq: "illustration/Custom CSS_v1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationBrand: file(relativePath: { eq: "illustration/brand.png" }) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationAddress: file(
        relativePath: { eq: "illustration/address.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationPlatforms: file(
        relativePath: { eq: "illustration/platforms.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
      illustrationIntegration: file(
        relativePath: { eq: "illustration/integration.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 500, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
  return (
    <>
      <Header name={name} />
      <div style={{ backgroundColor: "#fdf7ed" }}>
        <ThreeColumns>
          <ThreeColumns.Column
            list={[
              { img: data.illustrationAddress },
              {
                label: strings["addressText"],
              },
            ]}
          />
          <ThreeColumns.Column
            list={[
              { img: data.illustrationCost },
              {
                label: strings["costText"],
              },
            ]}
          />
          <ThreeColumns.Column
            list={[
              { img: data.illustrationIntegration },
              {
                label: strings["integrationText"],
              },
            ]}
          />
        </ThreeColumns>
      </div>
      <ClientBrandsBlue />
      {children}
      <div style={{ background: "#c0e5ff" }}>
        <ThreeColumns
          footer={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SmallButton
                background="#044e7a"
                classNameForGTM={`Link-${name}-Body-Features`}
                href={`/${locale}/features`}
                label={strings["fullFeatureList"]}
              />
            </div>
          }
          title={strings["topFeatures"]}
        >
          <ThreeColumns.Column
            list={[
              { img: data.illustrationBrand },
              {
                ...strings["brandColumns"][0],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["brandColumns"][1],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["brandColumns"][2],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["brandColumns"][3],
                background: "rgba(255, 252, 246, 0.4)",
              },
            ]}
          />
          <ThreeColumns.Column
            list={[
              { img: data.illustrationAddress },
              {
                ...strings["addressColumns"][0],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["addressColumns"][1],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["addressColumns"][2],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["addressColumns"][3],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["addressColumns"][4],
                background: "rgba(255, 252, 246, 0.4)",
              },
            ]}
          />
          <ThreeColumns.Column
            list={[
              { img: data.illustrationPlatforms },
              {
                ...strings["platformsColumns"][0],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["platformsColumns"][1],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["platformsColumns"][2],
                background: "rgba(255, 252, 246, 0.4)",
              },
              {
                ...strings["platformsColumns"][3],
                background: "rgba(255, 252, 246, 0.4)",
              },
            ]}
          />
        </ThreeColumns>
      </div>
      <Prices />
    </>
  );
}

Integration.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(Integration);
