import styles from "./EmbededCode.module.scss";

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import i18n from "./EmbededCode.i18n.json";
import addI18nContextConsumer from "../i18n/addI18nContextConsumer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { IFRAME_APP_BASEURL, BACK_OFFICE_BASEURL } from "../../conf/conf";

import { ERRORS } from "../retrieve-bl-info/RetrieveBLInfo";

const POPUP_OPTIONS =
  "menubar=no,location=no,scrollbars=yes,width=800,height=800,status=no,resizable=yes,top=0,left=0,dependent=yes,alwaysRaised=yes";

class EmbededCode extends Component {
  constructor(props) {
    super(props);
    const error =
      typeof window !== "undefined" && localStorage.getItem("error");
    if (error) {
      localStorage.removeItem("error");
    }
    this.state = {
      blid:
        typeof window !== "undefined" ? localStorage.getItem("BLID") : void 0,
      accessToken:
        typeof window !== "undefined"
          ? localStorage.getItem("accessToken")
          : void 0,
      error,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  getCode = (blid, accessToken, version = "latest") => {
    if (!IFRAME_APP_BASEURL || !blid || !accessToken) {
      return;
    }
    return `<iframe src="${IFRAME_APP_BASEURL}?access-token=${accessToken}&BLID=${blid}&version=${version}" style="width:100%;height:100%"></iframe>`;
  };

  openPopup = () => {
    const { locale } = this.props;
    window.open(`/${locale}/retrieveblinfo`, "_blank", POPUP_OPTIONS);
  };

  handleRetrieveCode = (hasInfo) => {
    if (hasInfo) {
      return;
    }
    this.openPopup();
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        const blid = localStorage.getItem("BLID");
        const accessToken = localStorage.getItem("accessToken");
        const error = localStorage.getItem("error");
        if ((!!blid && !!accessToken) || !!error) {
          clearInterval(this.intervalId);
          this.intervalId = void 0;
          if (error) {
            localStorage.removeItem("error");
          }
          this.setState({ blid, accessToken, error });
        }
      }, 300);
    }
  };

  renderError(error) {
    const { strings } = this.props;
    switch (error) {
      case ERRORS.NO_BUSINESS_LOCATOR:
        return (
          <div className={styles["error"]}>
            <div>
              {strings["error-no-bl"]}&nbsp;
              <a
                href={BACK_OFFICE_BASEURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                {strings["link-create-bl"]}
              </a>
            </div>
          </div>
        );
      case ERRORS.REQUEST_ERROR:
      case ERRORS.DATA_MISSING:
      default:
        return (
          <div className={styles["error"]}>
            <div>{strings["error-request"]}</div>
          </div>
        );
    }
  }

  render() {
    const { strings } = this.props;
    const { blid, accessToken, error } = this.state;
    const hasInfo = !!blid && !!accessToken;
    const code = this.getCode(blid, accessToken);
    return (
      <div>
        <div
          className={classNames(styles["label"], {
            [styles["hasInfo"]]: hasInfo,
          })}
          onClick={() => this.handleRetrieveCode(hasInfo)}
        >
          {strings["embedCode"]}
        </div>
        {hasInfo && !error && code && (
          <div>
            <div className={styles["code_container"]} id="Preview-Code">
              <div className={styles["value"]}>{code}</div>
              <div className={styles["button"]}>
                <div
                  className={styles["copy_btn"]}
                  onClick={() => {
                    navigator.clipboard.writeText(code);
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </div>
              </div>
            </div>
          </div>
        )}
        {error && this.renderError(error)}
      </div>
    );
  }
}

EmbededCode.propTypes = {
  locale: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(EmbededCode);
