/*eslint-disable */
import React from "react";

export default {
  en: {
    addressText: (
      <span>
        Locate your stores{" "}
        <span style={{ fontWeight: "normal" }}>
          so your customers can find you in just a few clicks.
        </span>
      </span>
    ),
    costText: (
      <span>
        Save money{" "}
        <span style={{ fontWeight: "normal" }}>
          with our competitive pricing
        </span>
      </span>
    ),
    integrationText: (
      <span>
        Install on any app or website{" "}
        <span style={{ fontWeight: "normal" }}>in just a few minutes.</span>
      </span>
    ),
    fullFeatureList: "See all features",
    freeTrial: "Try the Beta version",
    topFeatures: "Top Features",
    brandColumns: [
      {
        label: "A map to match your brand",
        text: "Customize your map theme and your markers to fit your brand. Or use one of our default styles to save time.",
      },
      {
        label: "Easily add and edit locations",
      },
      {
        label: "Responsive",
      },
      {
        label: "Custom CSS",
      },
    ],
    addressColumns: [
      {
        label: "Address Search",
        text: "A search bar can be integrated into every map so that users can search depending on their location (or whatever location they want).",
      },
      {
        label: "Install in seconds",
      },
      {
        label: "Insightful Metrics",
      },
      {
        label: "Smart Clustering",
      },
      // {
      //   label: "Multiple Languages",
      //   subLabel: "(coming soon!)"
      // }
    ],
    platformsColumns: [
      {
        label: "Works on all platforms",
        text: "Our store locator works flawlessly across all website platforms and custom websites : Shopify, Wix, Wordpress and more!",
      },
      {
        label: "Distance Calculator",
      },
      {
        label: "Helpful Support",
      },
      // {
      //   label: "Filter by Tag",
      //   subLabel: "(coming soon!)"
      // }
    ],
  },
  fr: {
    addressText: (
      <span>
        Localisez vos magasins{" "}
        <span style={{ fontWeight: "normal" }}>
          afin que vos clients puissent vous trouver en quelques clics.
        </span>
      </span>
    ),
    costText: (
      <span>
        Économisez{" "}
        <span style={{ fontWeight: "normal" }}>
          avec nos tarifs très compétitifs.
        </span>
      </span>
    ),
    integrationText: (
      <span>
        Installez le sur n'importe quel application ou site internet{" "}
        <span style={{ fontWeight: "normal" }}>en quelques minutes.</span>
      </span>
    ),
    fullFeatureList: "Voir toutes les fonctionnalités",
    freeTrial: "Essayer la version Beta",
    topFeatures: "Fonctionnalités",
    brandColumns: [
      {
        label: "UX de pointe",
        text: "Personnalisez le thème de votre carte et de vos marqueurs en fonction de votre marque. Ou utilisez l'un de nos styles par défaut pour gagner du temps.",
      },
      {
        label: "Ajouter et modifier facilement des emplacements",
      },
      {
        label: "Responsive",
      },
      {
        label: "CSS personnalisable",
      },
    ],
    addressColumns: [
      {
        label: "Recherche d'adresse",
        text: "Une barre de recherche peut être intégrée afin que les utilisateurs puissent rechercher en fonction de leur emplacement (ou de l'emplacement qu'ils souhaitent).",
      },
      {
        label: "Installé en quelques secondes",
      },
      {
        label: "Métriques intelligentes",
      },
      {
        label: "Clustering intelligent",
      },
      {
        label: "Multiples Languages",
        subLabel: "(coming soon!)",
      },
    ],
    platformsColumns: [
      {
        label: "Fonctionne sur toutes les plateformes",
        text: "Notre store locator fonctionne parfaitement sur toutes les plateformes de sites Web et les sites codés sur mesure.",
      },
      {
        label: "Calcul de distance",
      },
      {
        label: "Support à l'écoute",
      },
      {
        label: "Filtre par tag",
        subLabel: "(coming soon!)",
      },
    ],
  },
};
