import styles from "./Header.module.scss";

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";

import i18n from "./Header.i18n.json";
import addI18nContextConsumer from "../../../i18n/addI18nContextConsumer";
import SmallButton from "../../../button/small-button/SmallButton";
import Button from "../../../button/Button";

function Header({ name, strings, locale }) {
  const smallScreen =
    typeof window !== "undefined" &&
    window.matchMedia("(max-width: 768px)").matches;
  return (
    <div className={styles["container"]}>
      <div className={styles["content"]}>
        <h1>
          <span className={styles["bold"]}>{strings["businesslocator"]}</span>
          &nbsp;{strings["for"]}&nbsp;{name}
        </h1>
        <h2>
          <span className={styles["icon"]}>
            <FontAwesomeIcon icon={faBolt} />
          </span>
          <span className={styles["text"]}>{strings["title"]}</span>
        </h2>
        <h3>{strings["subtitle"]}</h3>
        <div className={styles["btn"]}>
          <div className={styles["askdemo"]}>
            {smallScreen ? (
              <SmallButton
                background="#0094ff"
                classNameForGTM={`Link-${name}-Top-Subscribe`}
                href="app"
                label={strings["askdemo"]}
                noBorder
              />
            ) : (
              <Button
                className={`Link-${name}-Top-Subscribe`}
                href="/app"
                label={strings["askdemo"]}
              />
            )}
          </div>
          <div>
            {smallScreen ? (
              <SmallButton
                background="#004b78"
                classNameForGTM={`Link-${name}-Top-Pricing`}
                href={`/${locale}/pricing`}
                label={strings["pricing"]}
                noBorder
              />
            ) : (
              <Button
                className={classNames(
                  styles["price_btn"],
                  `Link-${name}-Top-Pricing`
                )}
                href={`/${locale}/pricing`}
                label={strings["pricing"]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  locale: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(Header);
